<template>
  <v-btn
    :loading="regenerating"
    color="primary"
    depressed
    small
    :disabled="disabled"
    @click="regenerateDocs(elementId)"
  >
    Reg. cert.<v-icon size="18" class="ml-2">mdi-reload</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    elementId: { type: Number, default: 0 },
  },
  data: () => ({
    regenerating: null,
  }),
  methods: {
    async regenerateDocs(id) {
      try {
        this.regenerating = true;
        await this.$store.state.currentService.regenerateDocs(id);
        this.$notifySuccess(undefined, "Se han regenerado los certificados correctamente.");
      } catch (e) {
        this.$notifyError(undefined, "Error al regenerar los certificados | " + e);
      } finally {
        this.regenerating = false;
      }
    },
  },
};
</script>

<style scoped></style>
